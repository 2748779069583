import React, { useState, useEffect } from "react"
import { useMediaQuery } from "@mui/material";
import './App.css'

import ReactDOM from 'react-dom'
import LazyLoad from 'react-lazyload'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import FilterBar from "./components/FilterBar"

// import FRENLIST from "./frens/json_xchain/_metadata.json"
import FRENLIST from "./frens/punklist.json"

function App() {
	const [frenList, setFrenList] = useState([]);
	const [fullList, setFullList] = useState([]); // State to keep track of the full unfiltered list
	const [nameFilter, setNameFilter] = useState("");
	const [currentSelection, setCurrentSelection] = useState("Dispensers");

	const [attributeFilters, setAttributeFilters] = useState({});
	const [selectedFilters, setSelectedFilters] = useState({});
	const [drawerOpen, setDrawerOpen] = useState(false);
    const buttonStyle = {
    marginRight: '24px',
  };
	// price filter
	const [minPrice, setMinPrice] = useState(0);
	const [maxPrice, setMaxPrice] = useState(Infinity);

	// Sorting order state ('asc' for ascending, 'desc' for descending, null for no sorting)
	const [sortOrder, setSortOrder] = useState(null);

	// const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	// create a const called isMobile that is true if the screen is less than 400px in width
	const isMobile = useMediaQuery('(max-width:400px)');

	// Close drawer on mobile when component mounts
	useEffect(() => {
		if (isMobile) {
			setDrawerOpen(false);
		}
	}, [isMobile]);
	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};

	// Fetches the full list on mount and when the selection changes
	useEffect(() => {
		if (currentSelection) {
			setFullList(FRENLIST);
		} else {
			setFullList(FRENLIST); // Assuming FRENLIST is your full list
			applyNameFilter(FRENLIST);
		}
	}, [currentSelection]);



	const applyAllFilters = () => {
		let filteredList = fullList;

		// Apply name filter
		if (nameFilter) {
			filteredList = filteredList.filter(fren =>
				fren.name.toLowerCase().includes(nameFilter.toLowerCase())
			);
		}

		// Apply attribute filters
		if (Object.keys(selectedFilters).length > 0) {
			filteredList = filteredList.filter(fren =>
				Object.entries(selectedFilters).every(([trait, values]) =>
					fren.attributes.some(attr =>
						attr.trait_type === trait && values.has(attr.value)
					)
				)
			);
		}

		
		setFrenList(filteredList);
	};

	useEffect(() => {
		applyAllFilters();
	}, [nameFilter, selectedFilters, minPrice, maxPrice, sortOrder, fullList]);


	// Apply name filter
	const applyNameFilter = (list) => {
		const filtered = nameFilter ? list.filter(fren => fren.name.toLowerCase().includes(nameFilter.toLowerCase())) : list;
		setFrenList(filtered);
	}

	useEffect(() => {
		applyNameFilter(fullList);
	}, [nameFilter, fullList]);

	// Handle filter change
	function handleFilterChange(filterValue) {
		setNameFilter(filterValue);
	}

	useEffect(() => {
		// Initialize attribute filters with counts
		const initialFilters = {};
		FRENLIST.forEach(fren => {
			fren.attributes.forEach(attr => {
				if (!initialFilters[attr.trait_type]) {
					initialFilters[attr.trait_type] = {};
				}
				initialFilters[attr.trait_type][attr.value] = (initialFilters[attr.trait_type][attr.value] || 0) + 1;
			});
		});
		setAttributeFilters(initialFilters);
	}, []);

	const handleAttributeFilterChange = (trait, value, checked) => {
		setSelectedFilters(prevFilters => {
			const newFilters = { ...prevFilters };
			if (checked) {
				if (!newFilters[trait]) {
					newFilters[trait] = new Set();
				}
				newFilters[trait].add(value);
			} else {
				newFilters[trait]?.delete(value);
				if (newFilters[trait]?.size === 0) {
					delete newFilters[trait];
				}
			}
			return newFilters;
		});
		applyAttributeFilters();
	};

	const applyAttributeFilters = () => {
		if (Object.keys(selectedFilters).length === 0) {
			setFrenList(fullList);
		} else {
			const filteredList = fullList.filter(fren =>
				Object.entries(selectedFilters).every(([trait, values]) =>
					fren.attributes.some(attr =>
						attr.trait_type === trait && values.has(attr.value)
					)
				)
			);
			setFrenList(filteredList);
		}
	};

	// Apply price filter
	const applyPriceFilter = () => {
		const filtered = fullList.filter(fren => {
			console.log(minPrice, maxPrice, (fren.satoshirate) / 100000000)
			const price = fren.satoshirate ? parseFloat((fren.satoshirate) / 100000000) : 0;
			return price >= minPrice && price <= maxPrice;
		});
		setFrenList(filtered);
	};

	useEffect(() => {
		applyAttributeFilters();
	}, [selectedFilters, fullList]);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						Btc Dead Punk
					</Typography>
                    <Button color="warning" style={buttonStyle} variant="contained" href="https://ordinalgenesis.xyz/mint/be5b329fd7d5661a">Mint </Button>
					
					<Button color="warning" variant="contained" onClick={toggleDrawer}>Filters</Button>
				</Toolbar>
			</AppBar>

			<Grid container>
				<Grid item xs={1} md={1}>
					<Drawer
						open={drawerOpen}
						onClose={toggleDrawer}
						variant={isMobile ? "temporary" : "persistent"}
					>
						<Box p={2} sx={{ width: 250 }}>

							<FilterBar handleFilterChange={handleFilterChange} frenListData={FRENLIST} />


							<Divider />





							<List>
								{Object.entries(attributeFilters).map(([trait, values]) => (
									<React.Fragment key={trait}>
										<ListItem>
											<Typography variant="h6">{trait}</Typography>
										</ListItem>
										<Divider />
										{Object.entries(values).map(([value, count]) => (
											<ListItem key={value}>
												<FormControlLabel
													control={
														<Checkbox
															onChange={(e) => handleAttributeFilterChange(trait, value, e.target.checked)}
														/>
													}
													label={`${value} (${count})`}
												/>
											</ListItem>
										))}
									</React.Fragment>
								))}
							</List>

						</Box>
					</Drawer>
				</Grid>

				<Grid item xs={12} md={12}>
					<Box p={3} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Grid container spacing={3} justifyContent="center">
							{typeof frenList[0] !== "undefined" && frenList.length > 0 ? frenList.map((fren, i) => {
								return (
									<FrenCard key={i} fren={fren} />
								);
							}) : null}
						</Grid>
					</Box>

				</Grid>
			</Grid>
		</Box>
	)
}

export default App

function FrenCard(props) {
    const [expanded, setExpanded] = React.useState(false);
    const [frenInfo, setFrenInfo] = useState({
        name: "", image: "", background: "", type: "", head: "", eyes: "", accessories: "", mouth: "", hands: "", edition: 0, link: ""
    });

    // Fren boxes
    useEffect(() => {
        if (props.fren) {
            const attributes = props.fren.attributes.reduce((acc, attr) => {
                acc[attr.trait_type.replace(/\s+/g, '').toLowerCase()] = attr.value;
                return acc;
            }, {});

            setFrenInfo({
                name: props.fren.name,
                a_name: props.fren.a_name,
                image: props.fren.image,
                link: props.fren.link, // Link bilgisini ekledik
                ...attributes
            });
        }
    }, [props]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

 

    return (
        <Grid item>
            {props.fren && typeof props.fren.name !== "undefined"
                ? <LazyLoad height={250}>
                    <Card sx={{ maxWidth: 250, p: 0 }}>
                        <CardMedia
                            component="img"
                            alt={frenInfo.name}
                            height="250"
                            src={frenInfo.image}
                        />
                        <CardActions disableSpacing>
                            <Typography variant="body1" display="block" gutterBottom sx={{ m: 0, p: 0 }}>
                                {frenInfo.name}
                            </Typography>
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Typography variant="b2" color="text.secondary" sx={{ p: 0, mt: 1 }} component="div">
                                    ATTRIBUTES
                                </Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ p: 0, m: 1 }} component="div">
                                    Background: {frenInfo.background}<br />
                                    Type: {frenInfo.type}<br />
                                    Head: {frenInfo.head ? (
                                        <span>{frenInfo.head}</span>
                                    ) : (
                                        <span >None</span>
                                    )}<br />
                                    Eyes: {frenInfo.eyes ? (
                                        <span>{frenInfo.eyes}</span>
                                    ) : (
                                        <span >None</span>
                                    )}<br />
                                    Accessories: {frenInfo.accessories ? (
                                        <span>{frenInfo.accessories}</span>
                                    ) : (
                                        <span >None</span>
                                    )}<br />
                                    Mouth: {frenInfo.mouth ? (
                                        <span>{frenInfo.mouth}</span>
                                    ) : (
                                        <span >None</span>
                                    )}<br />
                                    Hands: {frenInfo.hands ? (
                                        <span>{frenInfo.hands}</span>
                                    ) : (
                                        <span >None</span>
                                    )}<br />
                                </Typography>
                            </CardContent>
                        </Collapse>
                    </Card>
                </LazyLoad>
                : null}
        </Grid>
    );
}


const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));
