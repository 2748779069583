import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { createFilterOptions } from '@mui/material/Autocomplete';

export default function FilterBar(props) {
    const [searchVal, setSearchVal] = useState("");

    useEffect(() => {
        console.log(searchVal);
    }, [searchVal]);

    const filterOptions = createFilterOptions({
        stringify: (option) => option
    });

    const handleAutocompleteChange = (event, value, reason) => {
        setSearchVal(value);

        if (reason === 'clear') {
            // props.resetAttributeFilters();
        }

        props.handleFilterChange(value);
    };

    return (
        <Stack spacing={2} sx={{ width: "100%",  margin: 1 }}>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.handleFilterChange(searchVal);
            }}>
                <Autocomplete
                    id="filter-bar"
                    freeSolo
                    filterOptions={filterOptions}
                    value={searchVal}
                    onChange={handleAutocompleteChange}
                    options={props.frenListData.map(item => item.name)}
                    renderInput={(params) => (
                        <TextField {...params} label="Search" size="small" />
                    )}
                />
            </form>
        </Stack>
    );
}
